import { ReactElement } from 'react';
import type { AppProps } from 'next/app';
import cache from 'src/theming/cache';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { CacheProvider } from '@emotion/react';
import theme from 'src/theming/theme';
import 'simplebar/dist/simplebar.min.css';
import 'react-virtualized/styles.css';
import 'cropperjs/dist/cropper.css';
import '../styles/globals.scss';

function MyApp({ Component, pageProps }: AppProps): ReactElement {
    return (
        <CacheProvider value={cache}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Component {...pageProps} />
            </ThemeProvider>
        </CacheProvider>
    );
}

export default MyApp;
