import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    fontWeight: 400,
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#95c11f',
            dark: '#629000',
            light: '#c9f457',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#989898',
            light: '#c9c9c9',
            dark: '#6a6a6a',
            contrastText: '#FFFFFF',
        },
        error: {
            main: '#f50404',
            dark: '#b90000',
            light: '#ff5937',
        },
    },
});

export default theme;
